// extracted by mini-css-extract-plugin
export var actions = "recommendation-screen-module--actions--f417c";
export var actionsButtons = "recommendation-screen-module--actions-buttons--a3323";
export var arrow = "recommendation-screen-module--arrow--8977b";
export var backgroundTa = "recommendation-screen-module--backgroundTa--881a2";
export var backgroundTaImage = "recommendation-screen-module--backgroundTaImage--26159";
export var button = "recommendation-screen-module--button--659c9";
export var description = "recommendation-screen-module--description--54ae9";
export var emptyButton = "recommendation-screen-module--emptyButton--47204";
export var section = "recommendation-screen-module--section--55c49";
export var ta = "recommendation-screen-module--ta--54088";
export var taName = "recommendation-screen-module--ta-name--06a09";
export var taPosition = "recommendation-screen-module--ta-position--eb9dd";
export var text = "recommendation-screen-module--text--78471";
export var title = "recommendation-screen-module--title--63e65";