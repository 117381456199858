// extracted by mini-css-extract-plugin
export var buttonActive = "hiring-process-module--button-active--6acde";
export var buttonDisabled = "hiring-process-module--button-disabled--c5826";
export var cardDescription = "hiring-process-module--card-description--49242";
export var cardHeading = "hiring-process-module--card-heading--3dcf3";
export var cardTitle = "hiring-process-module--card-title--bf5b1";
export var container = "hiring-process-module--container--f8584";
export var description = "hiring-process-module--description--5dc07";
export var heading = "hiring-process-module--heading--711d9";
export var hidden = "hiring-process-module--hidden--3e213";
export var hiringProcessCard = "hiring-process-module--hiring-process-card--90cab";
export var line = "hiring-process-module--line--56de0";
export var long = "hiring-process-module--long--adc91";
export var next = "hiring-process-module--next--07e86";
export var orange = "hiring-process-module--orange--7baf8";
export var pink = "hiring-process-module--pink--cc7a8";
export var prev = "hiring-process-module--prev--a6202";
export var section = "hiring-process-module--section--e2b07";
export var short = "hiring-process-module--short--384ed";
export var slider = "hiring-process-module--slider--baf4d";
export var sliderNav = "hiring-process-module--slider-nav--2a7d8";
export var sliderNavContainer = "hiring-process-module--slider-nav-container--5b5ba";
export var title = "hiring-process-module--title--49ed8";
export var violet = "hiring-process-module--violet--72b5c";
export var visible = "hiring-process-module--visible--3798e";