// extracted by mini-css-extract-plugin
export var arrow = "vacancies-screen-module--arrow--17589";
export var button = "vacancies-screen-module--button--a11a5";
export var buttonContainer = "vacancies-screen-module--button-container--e6ce6";
export var img = "vacancies-screen-module--img--cabba";
export var schedule = "vacancies-screen-module--schedule--d8c8b";
export var section = "vacancies-screen-module--section--596a8";
export var title = "vacancies-screen-module--title--a21cd";
export var vacancies = "vacancies-screen-module--vacancies--a3fc3";
export var vacanciesList = "vacancies-screen-module--vacancies-list--883a7";
export var vacancy = "vacancies-screen-module--vacancy--113f7";
export var vacancyTitle = "vacancies-screen-module--vacancy-title--90061";